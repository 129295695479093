// eslint-disable-next-line import/no-cycle
import sum from 'lodash/sum';

import { AnyDocument, LenientReadingPosition } from '../../../types';
import makeLogger from '../../../utils/makeLogger';
// eslint-disable-next-line import/no-cycle
import { globalState, isStaffProfile, updateState } from '../../models';
import background from '../../portalGates/toBackground';

const logger = makeLogger(__filename, { shouldLog: false });

export const addExperiencePoints = async (
  newPosition: LenientReadingPosition,
  doc: AnyDocument,
  correlationId?: string,
): Promise<void> => {
  const docId = doc.id;
  const previousPosition = doc.readingPosition;

  const isChunked = doc.source_specific_data?.epub?.is_chunked ?? false;

  if (!docId) {
    return;
  }

  let totalWordsInDoc = 0;

  if (!isChunked) {
    // Doc is unchunked, so we simply return the word count
    totalWordsInDoc = doc.word_count ?? 0;
  } else {
    const transientDoc = globalState.getState().transientDocumentsData[docId];

    const spine = transientDoc?.spine;
    const manifest = transientDoc?.manifest;
    if (!spine || !manifest) {
      return;
    }

    const chunkMap = await background.loadChunkMapFromManifest(manifest);

    const chunksInSpine = Object.values(spine)
      .map((chunkId) => chunkMap[chunkId]?.word_count ?? 0);

    totalWordsInDoc = sum(chunksInSpine);
  }

  const progressDelta = (newPosition.scrollDepth ?? 0) - (previousPosition?.scrollDepth ?? 0);
  const wordsRead = totalWordsInDoc * progressDelta;

  if (wordsRead <= 0) {
    return;
  }

  logger.info(`adding ${wordsRead} of experience`);


  await updateState(
    (state) => {
      if (state.client.bookwise?.areWipFeaturesEnabled === undefined && !isStaffProfile(state)) {
        return;
      }

      if (!state.persistent.experience || state.persistent.experience < 0) {
        state.persistent.experience = 0;
      }
      state.persistent.experience += wordsRead;
      state.persistent.experience = Math.round(state.persistent.experience);
    },
    { userInteraction: 'null', eventName: 'add-experience-points', isUndoable: false, correlationId },
  );
};
