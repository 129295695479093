import type { DocumentChunkManifest, DocumentChunkMap } from '../types/chunkedDocuments';
import makeLogger from '../utils/makeLogger';
import type { CacheInstance } from './Cache';
import { Cache } from './cache.platform';
// eslint-disable-next-line import/no-cycle
import stores from './stores';

// same as MAX_DOCUMENT_CHUNKS_COUNT_PER_PAGE in reader/constants.py
const MAX_DOCUMENT_CHUNKS_COUNT_PER_PAGE = 120;
const MIN_TIME_BETWEEN_LOADS_FROM_SERVER = 3000; // millis

let lastLoadedFromServerAt: number | undefined;
let isLoadingFromServer = false;
let queuedManifestsCache: CacheInstance | null = null;

const logger = makeLogger(__filename, { shouldLog: false });

function getManifestQueue(): CacheInstance {
  if (!queuedManifestsCache) {
    queuedManifestsCache = Cache.createInstance({
      name: 'queuedManifestsToLoad',
    });
  }
  return queuedManifestsCache;
}

export function loadDocumentChunksInBackground() {
  if (isLoadingFromServer) {
    logger.warn('Already loading chunks in background, bailing.');
    return;
  }
  logger.debug('Starting process to load chunks from server');
  isLoadingFromServer = true;
  loadChunkBatchFromServer();
}

export async function clearManifestsQueuedForLoad() {
  logger.debug('Clearing manifests queued for load');
  await getManifestQueue().clear();
}

export async function queueLoadForChunksFromManifest(docId: string, manifest: DocumentChunkManifest) {
  if (!await getManifestQueue().getItem(docId)) {
    logger.debug('Queueing manifest', {
      docId,
      chunkCount: Object.keys(manifest).length,
    });
    await getManifestQueue().setItem(docId, manifest);
  } else {
    logger.debug('Manifest already queued for load', {
      docId,
      chunkCount: Object.keys(manifest).length,
    });
  }
}

/**
 * We don't want to overload the client and degrade performance, so we throttle the chunk requests.
 * This will combine and request chunks from multiple documents at the same time.
 */
async function loadChunkBatchFromServer() {
  const now = performance.now();
  const docIdsInQueue = await getManifestQueue().keys();
  // sort doc IDs in queue from most recently created to least recently created.
  // this means doc manifests will not be loaded in the order they are queued, which makes this process technically
  // not FIFO. but queueing by doc ID is a much simpler and safer implementation than writing and reading an array.
  docIdsInQueue.sort().reverse();
  logger.debug('Periodically loading chunk batch from server...', {
    lastLoadedFromServerAt,
    durationSinceLastLoad: lastLoadedFromServerAt && now - lastLoadedFromServerAt,
    docIdsInQueue,
  });
  lastLoadedFromServerAt = now;

  const chunkIdBatch: string[] = [];
  const docIdsInBatch: string[] = [];
  while (docIdsInQueue.length > 0) {
    const docId = docIdsInQueue.shift(); // get the first one i.e. the most recently created doc.
    if (!docId) {
      logger.error('>0 doc IDs in queue but pop() returns nullish', { docId, docIdsInQueue });
      return;
    }
    const manifest = await getManifestQueue().getItem<DocumentChunkManifest>(docId);
    if (!manifest) {
      logger.warn('Queued manifest was already popped, skipping..', {
        docId,
      });
      continue;
    }
    const chunkIdsToAdd = extractChunkIdsFromManifest(manifest);
    chunkIdBatch.push(...chunkIdsToAdd);
    docIdsInBatch.push(docId);
    logger.debug('Added chunk IDs to batch for loading', {
      docId,
      chunkIdsToAdd,
      chunkIdBatch,
    });
    if (chunkIdBatch.length >= MAX_DOCUMENT_CHUNKS_COUNT_PER_PAGE) {
      break;
    }
  }

  if (chunkIdBatch.length > 0) {
    logger.debug('Loading chunk batch from server', {
      chunkIdBatch,
      docIdsInBatch,
    });
    try {
      await stores.documentContentChunks.loadByIds(chunkIdBatch);
      // only remove doc IDs from queue once we know loading their chunks was successful.
      await Promise.all(docIdsInBatch.map((docId) => getManifestQueue().removeItem(docId)));
    } catch (e) {
      logger.error('Error loading chunks from server', { e, chunkIdBatch, docIdsInBatch });
    }
  }

  setTimeout(loadChunkBatchFromServer, MIN_TIME_BETWEEN_LOADS_FROM_SERVER);
}

function extractChunkIdsFromManifest(manifest: DocumentChunkManifest): string[] {
  // Normally, items from a Store endpoint are loaded using last updated timestamps, in descending order of IDs.
  // We don't use that method for chunks, but just for a consistent order of IDs on the client we sort the same way.
  return Object.values(manifest).sort((a, b) => Number(b) - Number(a));
}

export async function loadChunkMapFromManifest(manifest: DocumentChunkManifest): Promise<DocumentChunkMap> {
  const manifestChunkIds = extractChunkIdsFromManifest(manifest);
  logger.debug('Loading chunks from manifest', { manifestChunkIds });

  const chunkMapByDbId = await stores.documentContentChunks.loadByIds(manifestChunkIds);
  return Object.fromEntries(
    Object.values(chunkMapByDbId).map((chunk) => [chunk.internal_id, chunk]),
  );
}
