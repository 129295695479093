import * as SentryIntegrations from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { Integrations as SentryTracingIntegrations } from '@sentry/tracing';

import getSentryConfig from '../getSentryConfig';
import { isDevOrTest } from './environment';
import { ExecutionContext } from './executionContext';

class ExceptionHandler {
  enabled = !isDevOrTest;

  init(dsn: string, executionContext = ExecutionContext.Browser) {
    if (!this.enabled) {
      return;
    }
    const sentryConfig = getSentryConfig();
    const integrations = [new SentryIntegrations.Dedupe(), new SentryIntegrations.Offline()];
    if (executionContext === ExecutionContext.Browser) {
      // Browser tracing depends on the 'window' and 'document' globals, which don't exist inside a Shared Worker.
      integrations.unshift(
        new SentryTracingIntegrations.BrowserTracing({
          shouldCreateSpanForRequest(url: string): boolean {
            // Prevent internal Tauri IPC calls on Windows from being traced, breaks some CORS security rule.
            return !url.startsWith('http://ipc.localhost');
          },
        }),
      );
    }
    Sentry.init({
      ...sentryConfig,
      beforeSend(event) {
        event.contexts ??= {};
        event.contexts['Execution Context'] = {
          Name: executionContext,
        };
        return sentryConfig.beforeSend ? sentryConfig.beforeSend(event) : event;
      },
      dsn,
      integrations,
    });
  }

  captureMessage(...args: Parameters<typeof Sentry.captureMessage>) {
    if (!this.enabled) {
      return;
    }
    Sentry.captureMessage(...args);
  }

  captureException(...args: Parameters<typeof Sentry.captureException>) {
    if (!this.enabled) {
      return;
    }
    Sentry.captureException(...args);
  }

  setUser(...args: Parameters<typeof Sentry.setUser>) {
    if (!this.enabled) {
      return;
    }
    Sentry.setUser(...args);
  }

  setExtras(...args: Parameters<typeof Sentry.setExtras>) {
    if (!this.enabled) {
      return;
    }
    Sentry.setExtras(...args);
  }
}

export default new ExceptionHandler();
