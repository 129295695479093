import debounce from 'lodash/debounce';

// eslint-disable-next-line import/no-cycle
import {
  forceChunkContentLoadAtPosition,
  forceContentLoadForContainer,
  forceContentUnloadForContainer,
  getChunkContainerElements,
  getPositionForTtsFromCurrentScrollPosition,
  getRectsFromTtsPosition,
  loadSurroundingChunksAndUnloadAllOthers,
  scrollToTtsPosition,
} from '../../foreground/contentFramePortalGateInternalMethods';
import eventEmitter from '../../foreground/contentFramePortalGateInternalMethods/eventEmitter';
import {
  populateTtsAbleElements,
  TextToSpeechContentFrameError,
} from '../../foreground/contentFramePortalGateInternalMethods/textToSpeechUtils';
import { getChunkScrollDepthInMobile } from '../../foreground/scrollDepth';
import { getElementFromSerializedPosition } from '../../foreground/serializedPosition';
import type { ChunkContainerElement } from '../../foreground/types/chunkedDocuments';
import {
  getVisibleElementsWithinRectBounds,
  isIntersecting,
} from '../../foreground/utils/findCenteredElementInViewport';
import { findChunkContainerForNode } from '../../foreground/utils/findChunkContainerForNode';
import { makeUrlAbsolute } from '../../foreground/utils/getChunkContentFromFilename';
import getRangyClassApplier from '../../foreground/utils/getRangyClassApplier';
import { isNodeAnHTMLElement } from '../../foreground/utils/isNodeAnHTMLElement';
import {
  deserializeCanonicalRange,
  serializePositionAsCanonical,
} from '../../foreground/utils/locationSerialization/chunked';
import { findScrollTargetInChunkContainer } from '../../foreground/utils/scrollToChunk';
import type { LenientReadingPosition, ReadingPosition, TtsPosition, WordBoundary } from '../../types';
import { isContentRootWithMultipleChunks, isTextNode } from '../../typeValidators';
import nowTimestamp from '../../utils/dates/nowTimestamp';
import exceptionHandler from '../../utils/exceptionHandler.platform';
import makeLogger from '../../utils/makeLogger';
import { ScrollingManagerError } from './errors';
import type { MobileContentFrameWindow } from './types';

declare let window: MobileContentFrameWindow;

const IGNORED_DOCUMENT_CHILD_NODE_TAGS = new Set(['STYLE']);
function preventDefault(e: TouchEvent) {
  e.preventDefault();
}

export type ScrollListenerFunction = () => void;

export type SerializedPositionInfo = {
  serializedPosition: string;
  serializedPositionElementOffset: number;
};

export type PageRect = {
  // The top coordinate of the page (relative to entire document)
  top: number;
  // The bottom coordinate of the page (relative to entire document)
  bottom: number;
  // The height of the divider that sits at the bottom of the page
  bottomPageDividerHeight: number;
};

type ScrollingManagerEventListener = {
  target: HTMLElement | Document | MobileContentFrameWindow;
  eventName: string;
  callback: (args: unknown) => void;
};

const logger = makeLogger(__filename, { shouldLog: false, isInsideWebview: true });
export abstract class ScrollingManager {
  // This is a base class for all things scrolling
  document = document;
  headerComponent: HTMLElement | undefined;
  headerContainer: HTMLElement | undefined;
  documentTextContent: HTMLElement | undefined;
  documentRoot: HTMLElement | undefined;
  documentRootContainer: HTMLElement | undefined;
  headerContent: HTMLElement | undefined;
  headerImageContainer: HTMLElement | undefined;
  isOnResizeDisabled = false;
  ttsPosIndicator: HTMLElement | undefined;
  ttsPosIndicatorEnd: HTMLElement | undefined;
  ttsAutoScrollingEnabled = false;
  ttsAbleElements: HTMLElement[] = [];
  focusableElements: Element[] = [];
  readingPosition: LenientReadingPosition | null = null;
  wordBoundaries: WordBoundary[] = [];
  isScrollingDown = false;
  currentScrollValue = 0;
  previousScrollValue = 0;
  scrollTimer: ReturnType<typeof setTimeout> | undefined = undefined;
  scrollingEnabled = true;
  scrollEventsDisabledTimer: ReturnType<typeof setTimeout> | undefined = undefined;
  touchMoveThrottle = 0;
  scrollingEventsDisabled = true;
  updatingCenterElementDisabled = false;
  documentTextContentHeight = 0;
  pageHeight = 800;
  currentChunkIndex = 0;

  hapticsOnScrollEnabled = false;
  smoothAnimationsDisabled = false;
  scrollListeners: ScrollListenerFunction[] = [];
  eventListeners: ScrollingManagerEventListener[] = [];
  initialized = false;
  currentCenteredElementInfo: { element?: HTMLElement | undefined | null; scrollDelta?: number; } = {};
  bodyObserver: ResizeObserver | undefined;
  baseLineHeight = 28;
  window: MobileContentFrameWindow = window;
  leftClickAreaWidth: number;
  rightClickAreaWidth: number;
  spinnerTimeout: ReturnType<typeof setTimeout> | undefined = undefined;
  chunksLoading = 0;
  boundOnContentChanged: typeof this.onContentChanged | undefined;
  topPageSnapshotElement: HTMLElement | undefined;
  bottomPageSnapshotElement: HTMLElement | undefined;
  fpsStart = 0;
  fpsTrackingStartTime = 0;
  fpsCounter = 0;
  shouldLogFPS = false;
  hasFPSReachedThreshold = false;
  _debugInitialScrollPosition: LenientReadingPosition | null = null;


  _debouncedStartUpdatingCenterElement = debounce(() => {
    this.updatingCenterElementDisabled = false;
  }, 1000);

  _internalDocumentTextRanges: Range[] | undefined = undefined;


  constructor(window: MobileContentFrameWindow) {
    this.window = window;
    const deviceWidth = this.window.innerWidth;
    this.leftClickAreaWidth = Math.min(deviceWidth * 0.2, 120);
    this.rightClickAreaWidth = Math.max(deviceWidth * 0.8, deviceWidth - 120);
  }

  initializeCallback() {
    throw new ScrollingManagerError('On initialize callback was never created');
  }

  addEventListener(
    target: HTMLElement | Document | MobileContentFrameWindow,
    eventName: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    callback: (args: any) => void,
  ) {
    target.addEventListener(eventName, callback);
    this.eventListeners.push({ target, eventName, callback });
  }

  removeAllEventListeners() {
    for (const eventObject of this.eventListeners) {
      const { target, eventName, callback } = eventObject;
      target.removeEventListener(eventName, callback);
    }
  }

  getScrollingElement() {
    if (this.document.scrollingElement === null) {
      throw new ScrollingManagerError('ScrollingElement is null!');
    }
    return this.document.scrollingElement as HTMLElement;
  }

  getScrollingElementTop() {
    return this.getScrollingElement().scrollTop;
  }

  getScrollingElementMaxScroll() {
    const { scrollHeight } = this.getScrollingElement();
    return scrollHeight;
  }

  getAbsoluteScrollTopOfRange(element: HTMLElement | Element | Range) {
    return this.getScrollingElementTop() + element.getBoundingClientRect().top;
  }

  getAbsoluteClientRectOfElement(element: HTMLElement | Element | Range): DOMRect {
    const clientRect = element.getBoundingClientRect();
    const scrollTop = this.getScrollingElementTop();
    return {
      ...clientRect,
      top: scrollTop + clientRect.top,
      bottom: scrollTop + clientRect.bottom,
    };
  }

  getChunkContainerByIndex(index: number): ChunkContainerElement {
    const chunk = this.document.querySelector(`[data-chunk-index="${index}"]`);
    if (!chunk) {
      throw new ScrollingManagerError(`getChunkContainerByIndex no chunk with index ${index} found`);
    }
    return chunk as ChunkContainerElement;
  }

  /**
   * Returns the reading progress from the current serialized position between 0 and 1
   */
  getReadingProgress() {
    if (this._isScrolledToEndOfChunk()) {
      return 1;
    }

    let readingPositionElement: HTMLElement | undefined;
    const currentChunkElement = this.getChunkContainerByIndex(this.currentChunkIndex);

    if (this.readingPosition?.serializedPosition) {
      readingPositionElement = this.getElementFromSerializedPosition(
        this.readingPosition?.serializedPosition,
      );
    }

    return getChunkScrollDepthInMobile(currentChunkElement, readingPositionElement);
  }

  get isChunked() {
    if (!this.documentRoot) {
      return false;
    }
    return isContentRootWithMultipleChunks(this.documentRoot);
  }

  setScrollingElementTop(newTop: number) {
    this.getScrollingElement().scrollTop = newTop;
  }

  scrollingElementScrollTo({ top, behavior }: { top: number; behavior: 'smooth' | 'auto' | 'instant'; }) {
    this.getScrollingElement().scrollTo({ top, behavior });
  }

  init() {
    if (this.window.isScrollingManagerFPSLoggingEnabled) {
      this.logFPS();
    }
    this.disableScrollEvents();
    if (this.initialized) {
      throw new ScrollingManagerError(
        'ScrollingManager already initialized; make sure to not call init twice!',
      );
    }
    // Register functions for window so React Native can use them
    this.initializeHTMLComponents();
    if (!this.documentTextContent) {
      throw new ScrollingManagerError('ScrollingManagerInit documentTextContentContainer not found!');
    }
    const newHeight = this.documentTextContent?.getBoundingClientRect().height;
    if (newHeight) {
      this.documentTextContentHeight = newHeight;
    }
    this.createResizeObserver();
    this.boundOnContentChanged = this.onContentChanged.bind(this);
    eventEmitter.on('chunk-content-unloaded', this.boundOnContentChanged);
    eventEmitter.on('chunk-content-loaded', this.boundOnContentChanged);
    this.onContentChanged();
  }

  createResizeObserver() {
    // this set timeout accounts for a brief moment where fonts load but don't apply correctly,
    // resulting in an unnecessary resize event
    this.initializeHTMLComponents();
    if (!this.documentTextContent) {
      throw new ScrollingManagerError('ScrollingManagerInit documentTextContentContainer not found!');
    }
    if (this.bodyObserver) {
      return;
    }
    this.bodyObserver = new ResizeObserver(() => this.onResize(false));
    this.bodyObserver.observe(this.documentTextContent);
  }


  destroy() {
    this.destroyResizeObserver();
    if (this.boundOnContentChanged) {
      eventEmitter.off('chunk-content-unloaded', this.boundOnContentChanged);
      eventEmitter.off('chunk-content-loaded', this.boundOnContentChanged);
    }
  }

  destroyResizeObserver() {
    if (!this.documentTextContent) {
      throw new ScrollingManagerError('ScrollingManagerInit documentTextContentContainer not found!');
    }
    if (!this.bodyObserver) {
      return;
    }
    this.bodyObserver.unobserve(this.documentTextContent);
    delete this.bodyObserver;
  }

  onResize(force = false) {
    // This event is handled inside ResizeObserver which handles errors a bit annoyingly
    // eslint-disable-next-line no-alert
    alert(`HandleResize must be implemented in child class, ${force}`);
  }

  addScrollListener(func: ScrollListenerFunction) {
    this.scrollListeners.push(func);
  }

  disableScrollEventsForNMilliseconds(milliseconds = 1000) {
    this.scrollingEventsDisabled = true;
    if (this.scrollEventsDisabledTimer) {
      clearTimeout(this.scrollEventsDisabledTimer);
      this.scrollEventsDisabledTimer = undefined;
    }
    if (milliseconds > 0) {
      this.scrollEventsDisabledTimer = setTimeout(() => {
        this.scrollingEventsDisabled = false;
      }, milliseconds);
    } else {
      this.scrollingEventsDisabled = false;
    }
  }

  abstract enableAllPaginationElements(): void;
  abstract disableAllPaginationElements(): void;
  abstract updateCurrentCenteredElement(): void;
  abstract handleChunksLoading(): void;

  disableScrollEvents() {
    this.scrollingEventsDisabled = true;
  }

  enableScrollEvents() {
    if (this.scrollEventsDisabledTimer) {
      clearTimeout(this.scrollEventsDisabledTimer);
      this.scrollEventsDisabledTimer = undefined;
    }
    this.scrollingEventsDisabled = false;
  }

  async scrollToReadingPosition(readingPosition: LenientReadingPosition) {
    if (readingPosition.serializedPosition) {
      await forceChunkContentLoadAtPosition(readingPosition.serializedPosition);
      try {
        await this.scrollToSerializedPosition(
          readingPosition.serializedPosition,
          readingPosition.mobileSerializedPositionElementVerticalOffset ?? 0,
        );
      } catch (e) {
        exceptionHandler.captureException(e);
        this.scrollToStartOfDocument();
      }
    } else {
      this.scrollToStartOfDocument();
    }
    this.updateCurrentCenteredElement();
  }

  findChunkContainerForElement(element: HTMLElement | Node) {
    if (!this.documentTextContent) {
      throw new ScrollingManagerError(
        'ScrollToSerializedPosition no document text content container found',
      );
    }
    return findChunkContainerForNode(element, this.documentTextContent);
  }


  async loadChunkContentAtSerializedPosition(serializedPosition: string) {
    this.incrementChunksLoading();
    await forceChunkContentLoadAtPosition(serializedPosition);
    this.decrementChunksLoading();
  }

  incrementChunksLoading() {
    this.chunksLoading += 1;
    this.handleChunksLoading();
  }

  decrementChunksLoading() {
    this.chunksLoading = Math.max(this.chunksLoading - 1, 0);
    this.handleChunksLoading();
  }


  async loadChunkContentForContainer(chunkContainer: ChunkContainerElement) {
    this.incrementChunksLoading();
    await forceContentLoadForContainer(chunkContainer);
    this.decrementChunksLoading();
  }

  async unloadChunkContentForContainer(chunkContainer: ChunkContainerElement) {
    this.incrementChunksLoading();
    await forceContentUnloadForContainer(chunkContainer);
    this.decrementChunksLoading();
  }

  async scrollToSerializedPosition(serializedPosition: string, offset: number) {
    if (!this.documentTextContent) {
      throw new ScrollingManagerError(
        'ScrollToSerializedPosition no document text content container found',
      );
    }

    await this.loadChunkContentAtSerializedPosition(serializedPosition);
    const target = this.getElementFromSerializedPosition(serializedPosition);
    if (!target) {
      throw new ScrollingManagerError(
          `ScrollToSerializedPosition no target found for serialized position ${serializedPosition}`,
        );
    }
    this.currentCenteredElementInfo = { element: target, scrollDelta: offset };
    await this._scrollToRangeOrElement(target, offset, 'auto');
  }

  async scrollToSerializedRange(serializedRange: string, offset: number) {
    const firstPositionInOccurrence = serializedRange.split(',')[0];
    if (!this.documentTextContent) {
      throw new ScrollingManagerError(
        'ScrollToSerializedPosition no document text content container found',
      );
    }

    try {
      // TODO: Perhaps we want to load the chunk which contains the last occurrence too
      await this.loadChunkContentAtSerializedPosition(firstPositionInOccurrence);
      const range = deserializeCanonicalRange(
        serializedRange,
        this.documentTextContent,
        this.document,
        getRangyClassApplier(),
      ).nativeRange;
      if (!range) {
        throw new ScrollingManagerError(
          `scrollToSerializedRange no range found for serialized range ${serializedRange}`,
        );
      }

      this._scrollToRangeOrElement(range, offset, 'auto');
    } catch (e) {
      exceptionHandler.captureException(e);
    }
  }

  initializeHTMLComponents() {
    if (!this.headerContent) {
      const headerContentResult = this.document.querySelector<HTMLElement>('.header-content');
      if (!headerContentResult) {
        throw new ScrollingManagerError('No .header-content found');
      }
      this.headerContent = headerContentResult;
    }
    const headerImageContainerResult = this.document.getElementById('header-image-container');
    if (!headerImageContainerResult) {
      throw new ScrollingManagerError('No #header-image-container found');
    }
    this.headerImageContainer = headerImageContainerResult;
    if (!this.headerComponent) {
      const headerComponentResult = this.document.getElementById('document-header');
      if (!headerComponentResult) {
        throw new ScrollingManagerError('No #header found');
      }
      this.headerComponent = headerComponentResult;
    }
    if (!this.headerContainer) {
      const headerContainerResult = this.document.querySelector<HTMLElement>('.header-container');
      if (!headerContainerResult) {
        throw new ScrollingManagerError('No .header-container found');
      }
      this.headerContainer = headerContainerResult;
    }
    if (!this.documentTextContent) {
      const documentContentResult = this.document.getElementById('document-text-content');
      if (!documentContentResult) {
        throw new ScrollingManagerError('No #document-text-content found');
      }
      this.documentTextContent = documentContentResult;
    }
    if (!this.documentRoot) {
      const documentRootResult = this.document.querySelector<HTMLElement>('.document-root');
      if (!documentRootResult) {
        throw new ScrollingManagerError('No .document-root found');
      }
      this.documentRoot = documentRootResult;
    }
    if (!this.documentRootContainer) {
      const documentRootContainerResult =
        this.document.querySelector<HTMLElement>('.document-container');
      if (!documentRootContainerResult) {
        throw new ScrollingManagerError('No .document-container found');
      }
      this.documentRootContainer = documentRootContainerResult;
    }
    const ttsPosIndicatorResult = document.querySelector<HTMLElement>('.tts-position-indicator-start');
    if (!ttsPosIndicatorResult) {
      throw new ScrollingManagerError('No .tts-position-indicator-start found');
    }
    this.ttsPosIndicator = ttsPosIndicatorResult;
    const ttsPosIndicatorEndResult = document.querySelector<HTMLElement>('.tts-position-indicator-end');
    if (!ttsPosIndicatorEndResult) {
      throw new ScrollingManagerError('No .tts-position-indicator-end found');
    }
    this.ttsPosIndicatorEnd = ttsPosIndicatorEndResult;

    const bottomPageContentResult = this.document.querySelector<HTMLElement>(
      '.absolutely-positioned-content.bottom',
    );
    const topPageContentResult = this.document.querySelector<HTMLElement>(
      '.absolutely-positioned-content.top',
    );
    if (!bottomPageContentResult || !topPageContentResult) {
      throw new ScrollingManagerError('No bottom or top content element found');
    }
    this.bottomPageSnapshotElement = bottomPageContentResult;
    this.topPageSnapshotElement = topPageContentResult;
  }

  handleScrollFromHref() {}
  refreshPageSnapshotsForCurrentPage() {
    // Only implemented in paginated scrolling manager
  }

  setReadingPosition(pos: LenientReadingPosition | null) {
    this.readingPosition = pos;
  }

  updateWordBoundaries(wordBoundaries: WordBoundary[]) {
    this.wordBoundaries = wordBoundaries;
  }

  toggleTTSAutoScrolling(enabled: boolean) {
    this.ttsAutoScrollingEnabled = enabled;
  }

  abstract isDocumentScrolledToBeginning(): boolean;

  getElementFromSerializedPosition(serializedPosition: string): HTMLElement | undefined {
    return getElementFromSerializedPosition(serializedPosition, this.documentTextContent, true);
  }

  async playTtsFromCurrentScrollPosition() {
    if (!this.documentTextContent) {
      throw new ScrollingManagerError('Document element not found');
    }
    this.ttsAutoScrollingEnabled = false;

    const positionResult = await getPositionForTtsFromCurrentScrollPosition({
      contentContainer: this.documentTextContent,
      getIsDocumentScrolledToTop: () => this.isDocumentScrolledToBeginning(),
      ttsAbleElements: this.ttsAbleElements,
      window: this.window,
    });
    if (!positionResult) {
      return;
    }

    this.ttsAutoScrollingEnabled = true;
    const { elementIndex, position } = positionResult;

    if (typeof elementIndex !== 'undefined') {
      this.window.portalGateToForeground.emit('play-tts-from-element', { elementIndex, position });
      return;
    }
    this.window.portalGateToForeground.emit('play-tts-from-timestamp', { timestamp: position });
  }

  abstract scrollViewportToCurrentTTSLocation(rect: DOMRect): void;

  getScrollDepth() {
    if (this._isScrolledToEndOfChunk()) {
      return 1;
    }

    const currentChunkElement = this.getChunkContainerByIndex(this.currentChunkIndex);

    return getChunkScrollDepthInMobile(currentChunkElement);
  }

  getScrollDepthForTtsPosition(ttsPosition: TtsPosition): number | undefined {
    const scrollableRoot = this.getScrollingElement();
    const contentContainer = this.documentTextContent;
    if (!contentContainer || !scrollableRoot) {
      return;
    }
    const rect = getRectsFromTtsPosition({
      contentContainer,
      scrollableRoot,
      ttsPosition,
      returnRepeatedValue: true,
    })?.rect;
    if (!rect) {
      return;
    }
    return (rect.top + this.getScrollingElementTop() + 350) / (scrollableRoot.scrollHeight - scrollableRoot.clientHeight);
  }

  async scrollToTtsPosition(ttsPosition: TtsPosition, skipIndicatorUpdate: boolean) {
    const scrollableRoot = this.getScrollingElement();
    const contentContainer = this.documentTextContent;
    if (!contentContainer || !scrollableRoot) {
      return false;
    }

    // If TTS is not on, neither is auto-scrolling.
    // If that's the case, we want to briefly re-enable it to get to the position.
    const isScrollingManagerAutoScrollEnabled = this.ttsAutoScrollingEnabled;
    this.ttsAutoScrollingEnabled = true;
    await scrollToTtsPosition({
      contentContainer,
      scrollableRoot,
      ttsPosition,
      isAutoScrollEnabled: true,
      skipIndicatorUpdate,
      useRepeatedLastWord: true,
    });
    this.ttsAutoScrollingEnabled = isScrollingManagerAutoScrollEnabled;

    return true;
  }

  computeSerializedPositionFromCenteredElement(): SerializedPositionInfo | undefined {
    const { element, scrollDelta } = this.currentCenteredElementInfo;
    if (
      !this.documentTextContent ||
      !element ||
      scrollDelta === undefined ||
      !findChunkContainerForNode(element, this.documentTextContent)) {
      return;
    }
    const serializedPosition = serializePositionAsCanonical({
      classApplier: getRangyClassApplier(),
      node: element,
      offset: 0,
      rootNode: this.documentTextContent,
    });

    return {
      serializedPosition,
      serializedPositionElementOffset: scrollDelta,
    };
  }

  get currentScrollPosition(): ReadingPosition {
    const serializedPositionInfo = this.computeSerializedPositionFromCenteredElement();

    const currentScrollValue = this.getScrollingElementTop();
    const maxScrollValue = this.getScrollingElementMaxScroll();
    const { clientHeight } = this.getScrollingElement();

    return {
      serializedPosition: serializedPositionInfo?.serializedPosition ?? null,
      scrollDepth: Math.min(1, currentScrollValue / (maxScrollValue - clientHeight)),
      mobileSerializedPositionElementVerticalOffset: serializedPositionInfo?.serializedPositionElementOffset ?? 0,
    };
  }

  createHighlightAtTtsPosition(ttsPos: TtsPosition) {
    if (!this.documentTextContent) {
      throw new ScrollingManagerError('Document element not found');
    }
    const { paraIndex } = ttsPos;

    if (!this.ttsAbleElements.length) {
      populateTtsAbleElements(this.documentTextContent, this.ttsAbleElements);
    }
    const node = this.ttsAbleElements[paraIndex];

    if (!node.textContent) {
      throw new TextToSpeechContentFrameError('Could not find text node');
    }

    const highlightRange = new Range();
    highlightRange.selectNode(node);

    const selection = this.document.getSelection();
    if (!selection) {
      throw new TextToSpeechContentFrameError('no selection');
    }
    selection.removeAllRanges();
    selection.addRange(highlightRange);

    this.window.portalGateToForeground.emit('create-highlight');
  }

  scrollToTop() {
    this.setScrollingElementTop(0);
  }

  abstract scrollToStartOfDocument(): void;
  abstract returnToReadingPosition(): void;
  abstract onScrollStart(): void;

  // This is unthrottled, only add code to this if you need to listen to scroll a lot (like animating elements due to scroll position)
  abstract onScroll(): void;
  abstract onScrollEnd(): void;
  abstract onTouchMove(e: TouchEvent): void;

  onContentChanged() {
    if (!this.documentTextContent) {
      throw new ScrollingManagerError('onContentChanged Document Text Container not found');
    }
    this.ttsAbleElements = [];
    this.focusableElements = [];
    this._internalDocumentTextRanges = undefined;
    populateTtsAbleElements(this.documentTextContent, this.ttsAbleElements);
    logger.debug('onContentChanged', { highlightableElements: this.focusableElements });
  }

  toggleScrollingEnabled(enabled: boolean) {
    if (!enabled) {
      if (this.scrollingEnabled) {
        this.document.body.style.overflow = 'hidden';
        this.window.addEventListener('touchmove', preventDefault, false); // mobile
      }
      this.scrollingEnabled = false;
    } else {
      this.window.removeEventListener('touchmove', preventDefault, false);
      this.document.body.style.overflow = 'visible';
      this.scrollingEnabled = true;
    }
  }

  // Util function to help move a debug border to a coordinate, useful for visualizing coordinates
  drawDebugBorderAtCoordY(coordY: number) {
    const border = this.document.querySelector<HTMLElement>('.debug-border');
    if (!border) {
      return;
    }
    border.style.top = `${coordY}px`;
    border.style.display = 'block';
  }

  isElementIntersectingAtYCoord(element: Element, yCoord: number) {
    if (!this.documentRoot) {
      throw new ScrollingManagerError('No document root found');
    }
    const relativeYCoord = this.documentRoot.getBoundingClientRect().top + yCoord;
    return isIntersecting(
      {
        top: relativeYCoord,
        bottom: relativeYCoord + 1,
        left: 0,
        right: this.window.innerWidth,
      },
      element,
    );
  }

  get numberOfChunks(): number {
    return getChunkContainerElements().length;
  }

  get isCurrentChunkIndexFinal(): boolean {
    return this.currentChunkIndex === this.numberOfChunks - 1;
  }

  get documentTextContentRanges(): Range[] {
    if (!this.initialized) {
      return [];
    }
    if (this._internalDocumentTextRanges?.length) {
      return this._internalDocumentTextRanges;
    }
    if (!this.documentTextContent) {
      throw new ScrollingManagerError('No document text content found');
    }

    const shouldUseSecondLevelChildren = (children: HTMLCollection) => {
      if (children.length !== 1) {
        return false;
      }
      const firstChild = children[0];
      if (firstChild.nodeName !== 'DIV' && firstChild.nodeName !== 'SECTION') {
        return false;
      }
      // If a div has some padding or margin added, chances are that class might affect the look of the content
      // best to use this div even if we will have large ranges in our set
      const firstChildComputedMap = firstChild.computedStyleMap();
      const marginStyles = firstChildComputedMap.get('margin')?.toString() ?? '0px';
      const paddingStyles = firstChildComputedMap.get('padding')?.toString() ?? '0px';
      return marginStyles === '0px' || paddingStyles === '0px';
    };

    const childrenToUse: Range[] = [];
    const chunkContainers = this.documentTextContent.querySelectorAll('.rw-chunk-container');
    for (const chunkContainer of chunkContainers) {
      let topLevelDocumentChildren = Array.from(chunkContainer.childNodes);
      // if we only have one main element, and that element has children, most likely that's the element we are interested in
      if (shouldUseSecondLevelChildren(chunkContainer.children)) {
        topLevelDocumentChildren = Array.from(chunkContainer.children[0].childNodes)
          .filter((node) => !IGNORED_DOCUMENT_CHILD_NODE_TAGS.has(node.nodeName));
      }
      for (const child of topLevelDocumentChildren) {
        if (isNodeAnHTMLElement(child)) {
          const range = new Range();
          if (child.getBoundingClientRect().height > 0 || child.getBoundingClientRect().width > 0) {
            range.selectNode(child);
            childrenToUse.push(range);
          }
        } else if (isTextNode(child)) {
          const range = new Range();
          range.selectNode(child);
          if (range.getClientRects().length > 0) {
            childrenToUse.push(range);
          }
        }
      }
    }
    this._internalDocumentTextRanges = childrenToUse;
    return childrenToUse;
  }

  doesElementContainDirectTextNodes(element: Element) {
    for (const child of element.childNodes) {
      if (isTextNode(child) && child.textContent && child.textContent.replace('\n', '').length >= 1) {
        return true;
      }
    }
    return false;
  }

  getEfficientClientRectFromRange(range: Range) {
    const firstElement = this.getFirstNodeInRange(range);
    if (firstElement instanceof Element) {
      return firstElement.getBoundingClientRect();
    }
    return range.getBoundingClientRect();
  }

  getRangeAtY(yCoord: number, returnClosestElement = false): Range | null {
    if (!this.documentRoot) {
      throw new ScrollingManagerError('No document root found');
    }
    const allRanges = this.documentTextContentRanges;
    let left = 0;
    let right = allRanges.length - 1;
    let middleRange = null;
    while (left <= right) {
      const mid = Math.floor((left + right) / 2);
      middleRange = allRanges[mid];
      const clientRect = this.getEfficientClientRectFromRange(middleRange);

      const absoluteTopOfElement =
        clientRect.top + this.getScrollingElementTop();
      const absoluteBottomOfElement =
        clientRect.bottom + this.getScrollingElementTop();

      if (absoluteTopOfElement <= yCoord && absoluteBottomOfElement >= yCoord) {
        return middleRange;
      }
      if (absoluteBottomOfElement < yCoord) {
        // this range is above the ycoord
        left = mid + 1;
      } else if (absoluteTopOfElement > yCoord) {
        // this range is below the ycoord
        right = mid - 1;
      } else {
        // this else case makes no sense
        // for us to arrive here the element must
        // have a bottom coord below the ycoord and a top above ycoord
        // but it didn't intersect... ?
        // In any case I wouldn't want the loop to run forever so returning null here is ok
        return null;
      }
    }
    // If we got here that means we didnt get a super precise value but
    // we can still return the last seen middle range in our search
    if (middleRange && returnClosestElement) {
      return middleRange;
    }
    return null;
  }

  getAllRangesInRect(pageRect: PageRect, verticalMargin: number): Range[] {
    const viewportWidth = this.window.innerWidth;
    const relativePageRect = {
      top: pageRect.top - this.getScrollingElementTop() - verticalMargin,
      bottom: pageRect.bottom - this.getScrollingElementTop() + verticalMargin,
      left: 0,
      right: viewportWidth,
    };
    return getVisibleElementsWithinRectBounds(this.documentTextContentRanges, relativePageRect);
  }

  getTextContentFromElementOrRange(elementOrRange: Element | Range) {
    let textContent = '';
    if (elementOrRange instanceof Element) {
      textContent = elementOrRange.textContent ?? '';
    }
    if (elementOrRange instanceof Range) {
      textContent = elementOrRange.toString();
    }
    return textContent;
  }

  getFirstNodeInRange(range: Range): Node {
    return range.startContainer.childNodes[range.startOffset] ?? range.startContainer;
  }

  getNumberOfWordsInRect(rect: PageRect) {
    const visibleRanges = this.getAllRangesInRect(rect, 0);
    if (!visibleRanges.length) {
      return 0;
    }

    const firstRange = visibleRanges[0];
    const lastRange = visibleRanges[visibleRanges.length - 1];

    let totalWordCount = 0;

    // First we need to see if the firstRange gets cut-off at the top
    const scrollTopOfFirstElement = this.getAbsoluteScrollTopOfRange(firstRange);
    const firstElementHeight = firstRange.getBoundingClientRect().height;
    // We want to see what % of the element is visible within the provided rect
    // (e.g it might be half above and half inside the rect)
    const firstElementDistanceFromRectTop = rect.top - scrollTopOfFirstElement;
    let percentOfFirstElementVisible = 1;
    if (firstElementDistanceFromRectTop > 0) {
      // the first element is cut-off, figure out by how much
      const visibleHeight = Math.min(Math.max(0, firstElementHeight - firstElementDistanceFromRectTop), this.pageHeight);
      percentOfFirstElementVisible = Math.min(1, visibleHeight / firstElementHeight);
    }

    const firstElementTextContent = this.getTextContentFromElementOrRange(firstRange);

    totalWordCount += firstElementTextContent.split(' ').length * percentOfFirstElementVisible;

    for (let i = 1; i < visibleRanges.length - 1; i++) {
      const textContent = this.getTextContentFromElementOrRange(visibleRanges[i]);
      totalWordCount += textContent.split(' ').length;
    }


    if (lastRange === firstRange) {
      return Math.round(totalWordCount);
    }
    // Now we do the same with the last element

    const scrollTopOfLastElement = this.getAbsoluteScrollTopOfRange(lastRange);
    const lastElementHeight = lastRange.getBoundingClientRect().height;
    let percentOfLastElementVisible = 1;
    const lastElementDistanceFromBottom = rect.bottom - Math.max(scrollTopOfLastElement, this.getScrollingElementTop());
    if (lastElementDistanceFromBottom < 0) {
      percentOfLastElementVisible = 0;
    } else {
      percentOfLastElementVisible = Math.min(1, lastElementDistanceFromBottom / lastElementHeight);
    }

    const lastElementTextContent = this.getTextContentFromElementOrRange(lastRange);
    totalWordCount += lastElementTextContent.split(' ').length * percentOfLastElementVisible;
    return Math.round(totalWordCount);
  }

  getNumberOfWordsOnScreen() {
    if (!this.documentTextContent) {
      return 0;
    }
    const rect = {
      top: this.getScrollingElementTop(),
      bottom: this.getScrollingElementTop() + this.window.innerHeight,
      bottomPageDividerHeight: 0,
    };
    return Math.min(2000, this.getNumberOfWordsInRect(rect));
  }


  setSpinnerEnabled(enabled: boolean, instant = false) {
    const spinner = this.document.querySelector('.chunk-loading-spinner');
    if (!spinner) {
      return;
    }
    if (this.spinnerTimeout && enabled) {
      // we dont need to start it again, we already triggered it
      return;
    }
    if (!enabled) {
      if (this.spinnerTimeout) {
        clearTimeout(this.spinnerTimeout);
        this.spinnerTimeout = undefined;
      }
      spinner.classList.remove('chunk-loading-spinner-active');
      return;
    }
    if (!enabled && this.spinnerTimeout) {
      clearTimeout(this.spinnerTimeout);
    }
    this.spinnerTimeout = setTimeout(() => {
      const spinner = this.document.querySelector('.chunk-loading-spinner');
      if (!spinner) {
        return;
      }
      if (enabled) {
        spinner.classList.add('chunk-loading-spinner-active');
      }
    }, instant ? 1 : 1000);
  }

  async scrollToElementWithinChunk(elementPath: string, attribute: string) {
    if (!this.documentRoot) {
      throw new ScrollingManagerError('scrollToHeadingId: No document root found');
    }

    if (this.isChunked) {
        // The headingId for chunked epubs includes first the path to the chunk container
        // and then the id of the element within the chunk, e.g. "Text/chapter-5.html#p3"
      const [filename, headingId] = elementPath.split('#');
      const absoluteFilename = makeUrlAbsolute(filename);
      const container = this.documentRoot.querySelector<ChunkContainerElement>(`[data-chunk-filename="${absoluteFilename}"]`);
      if (!container) {
        exceptionHandler.captureException('Could not find chunk container from filename', { extra: { filename, absoluteFilename, headingId, elementPath } });
        return;
      }

      let scrollTarget: HTMLElement | Range | null;
      await this._loadOnlyContentSurroundingChunkContainer(container);

      if (headingId) {
      // The targetElementId is NOT unique across chunkContainers, so we need to use the chunkContainer's
      // element to find the target element
        scrollTarget = container.querySelector<HTMLElement>(`#${headingId}`);
        if (!scrollTarget) {
          // lets at least scroll to the top of the container
          scrollTarget = container;
        }
      } else {
        scrollTarget = findScrollTargetInChunkContainer(container);
      }

      if (!scrollTarget) {
        exceptionHandler.captureException('Could not find a scroll target within the chunk', { extra: { elementPath } });
        return;
      }

      await this._scrollToRangeOrElement(scrollTarget);
    } else {
      const strippedId = elementPath.replace('#', '');
      const elementToScrollTo = this.documentRoot.querySelector<HTMLElement>(`[${attribute}="${strippedId}"]`);
      if (!elementToScrollTo) {
        throw new ScrollingManagerError('scrollToHeadingId: No element to scroll to found');
      }
      this._scrollToRangeOrElement(elementToScrollTo);
    }
  }

  async scrollToHeadingWithId(elementPath: string, isEpub: boolean) {
    const attribute = isEpub ? 'data-rw-epub-toc' : 'id';
    return this.scrollToElementWithinChunk(elementPath, attribute);
  }

  logFPS() {
    this.shouldLogFPS = true;
    this.debugTrackFPS();
  }

  debugTrackFPS() {
    if (!this.shouldLogFPS) {
      return;
    }
    const logger = makeLogger('debugTrackFPS', {
      shouldLog: this.shouldLogFPS,
    });
    if (!this.fpsTrackingStartTime) {
      this.fpsTrackingStartTime = nowTimestamp();
    }
    if (!this.fpsStart) {
      this.fpsStart = nowTimestamp();
    }
    requestAnimationFrame(() => {
      if (this.fpsCounter > 20 && !this.hasFPSReachedThreshold) {
        logger.log('We are usable ', nowTimestamp() - this.fpsTrackingStartTime);
        this.hasFPSReachedThreshold = true;
      }
      if (nowTimestamp() - this.fpsStart >= 1000) {
        logger.log('fps: ', this.fpsCounter);
        this.fpsCounter = 0;
        this.fpsStart = nowTimestamp();
      }
      this.fpsCounter += 1;
      this.debugTrackFPS();
    });
  }

  abstract _isScrolledToEndOfChunk(): boolean;
  abstract _scrollToRangeOrElement(element: HTMLElement | Range, offset?: number, behavior?: 'smooth' | 'auto' | 'instant'): Promise<void>;

  protected async _loadOnlyContentSurroundingChunkContainer(container: ChunkContainerElement): Promise<void> {
    this.incrementChunksLoading();
    await loadSurroundingChunksAndUnloadAllOthers(container);
    this.decrementChunksLoading();
  }

  protected _temporarilyForceCenteredElement(element: HTMLElement, scrollDelta: number) {
    this.currentCenteredElementInfo = {
      element,
      scrollDelta,
    };
    this.updatingCenterElementDisabled = true;
    setTimeout(this._debouncedStartUpdatingCenterElement.bind(this), 2000);
  }
}
